<template>
  <div class="left-card-1">
    <div class="cargo-identification">
      <a-space>
        <img
          src="../assets/shibie.png"
          alt=""
          style="width: 0.12rem; height: 0.12rem"
        />
        <span>货物识别</span>
      </a-space>
      <div class="cargo-status">
        <GoodsStatus :deviceStatus="goodsIdentificationStatus ?? 2" />
        <!-- <CloseOutlined
          class="icons"
          :style="{ fontSize: '0.1rem', color: '#dc5a6e' }"
          v-if="identifyResult?.status === '识别异常'"
        />
        <CheckOutlined
          class="icons"
          :style="{ fontSize: '0.1rem', color: '#74fef5' }"
          v-if="identifyResult?.status === '识别完成'"
        />
        <a-spin
          class="icons"
          :indicator="indicator"
          v-if="identifyResult?.status === '识别中'"
        /> -->
      </div>
    </div>
    <div class="cargo-aggregatesSpecs">
      <span
        :class="{
          'identify-status-notconform': identifyResult?.status === '识别异常',
        }"
      >
        {{ identifyResult?.result ?? '--' }}
      </span>
    </div>
    <div class="divider"></div>
    <div class="cargo-result">
      <div
        class="identify-status-conform"
        v-if="
          identifyResult?.isConform == 1 &&
          identifyResult?.status === '识别完成'
        "
      >
        物料符合
      </div>
      <div
        class="identify-status-notconform"
        v-else-if="
          identifyResult?.isConform == 0 &&
          identifyResult?.status === '识别完成'
        "
      >
        物料不符
      </div>
      <div
        class="identify-status-notconform"
        v-else-if="identifyResult?.status === '识别异常'"
      >
        识别异常
      </div>
      <div class="identify-status-notconform" v-else>--</div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import GoodsStatus from './cpns/device/goods-status.vue';
// import {
//   LoadingOutlined,
//   CheckOutlined,
//   CloseOutlined,
// } from '@ant-design/icons-vue';
const store = useStore();

// const indicator = h(LoadingOutlined, {
//   style: {
//     fontSize: '20px',
//     color: '#fff',
//   },
//   spin: true,
// });
let identifyResult = ref({
  status: null, //识别状态
  result: null, //识别结果（10-20mm）
  isConform: null, //是否符合（1是，0否）
});
const goodsIdentificationStatus = ref(null);

watch(
  [
    () => store.state.engine.allEngineData.goodsIdentificationResult,
    () => store.state.engine.allEngineData.goodsIdentificationStatus,
  ],
  ([newResult, newStatus]) => {
    Object.assign(identifyResult.value, newResult);
    goodsIdentificationStatus.value = newStatus;
  },
  { immediate: false }
);
</script>

<style scoped>
@import '../../static/font.css';
.left-card-1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 3%;
}
.cargo-identification {
  padding: 0px 0.08rem;
  display: flex;
  width: 100%;
  height: 32%;
  border-radius: 5px 5px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.09rem;
  color: #ffffff;
  background-color: rgba(17, 34, 62, 0.5);
  font-weight: 600;
}
.cargo-status {
  margin-left: auto;
}
.cargo-aggregatesSpecs {
  padding: 0px 0.08rem;
  font-size: 0.08rem;
  color: #cccccc;
  height: 26%;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
}
.identify-status-notconform {
  font-size: 0.08rem;
  color: #fc5555;
  font-weight: 400;
}
.identify-status-conform {
  font-size: 0.08rem;
  color: #5bde75;
  font-weight: 400;
}
.cargo-result {
  height: 26%;
  width: 100%;
  padding: 0px 0.08rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.divider {
  width: 88%;
  height: 1px;
  margin: 0px 6%;
  /* background: radial-gradient(#2d538e, #2d538e, #2d538e); */
  background-color: #aad8fa;
}
</style>
