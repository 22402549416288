<template>
  <div class="right-card-1 card">
    <div class="card-top">
      <div class="card-top-left"></div>
      <div class="card-top-center">
        <span>
          {{
            `${finenessModulusResult?.workId?.slice(0, 4) || '-'}-${
              finenessModulusResult?.workId?.slice(-4) || '-'
            }`
          }}
        </span>
      </div>
      <div class="card-top-right">
        <div class="tabicon">
          <img
            src="../assets/table.png"
            style="margin-right: 0.04rem; cursor: pointer"
            @click="modlShow"
            v-if="!isCloudPage"
          />
        </div>
      </div>
    </div>
    <div class="card-center">
      <div class="card-center-left">
        <ul>
          <li>
            <div class="title"><span>类 型:</span></div>
            <div class="text-result">
              <span>{{ aggregateType ?? '--' }}</span>
            </div>
          </li>
          <li>
            <div class="title"><span>细度模数:</span></div>
            <div class="text-result">
              <span>
                {{ finenessModulusResult?.finenessModulus?.toFixed(2) ?? '--' }}
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div class="card-center-right">
        <ul>
          <li>
            <div class="yuansu"></div>
            <div class="title">试验前:</div>
            <div class="text">
              {{
                finenessModulusResult?.beforeSieveTotalQuality?.toFixed(1) ??
                '--'
              }}g
            </div>
          </li>
          <li>
            <div class="yuansu"></div>
            <div class="title">试验后:</div>
            <div class="text">
              {{
                finenessModulusResult?.afterSieveTotalQuality?.toFixed(1) ??
                '--'
              }}g
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-bottom" style="flex: 1">
      <v-chart class="chart" ref="mchart" autoresize :option="optionFineness" />
    </div>
    <!--表格弹框-->
    <a-modal
      v-model:visible="logShow"
      width="100%"
      wrap-class-name="table-modal"
      centered
      :closeIcon="true"
    >
      <template #title>
        <div ref="modalTitleRef" style="">颗粒级配（细集料）</div>
        <a-button class="close-btn" @click="logShow = false">关闭 </a-button>
      </template>
      <div class="modmian" style="padding: 0">
        <div class="modmian-top">
          <div class="modmian-top-item nonum">
            {{ totalData.aggreFateData?.testNumber }}
          </div>
          <div class="modmian-top-item">
            {{
              `【${totalData.aggreFateData?.aggregatesTypeParent}】 ${totalData.aggreFateData?.aggregatesSpecs}`
            }}
          </div>
          <div class="modmian-top-item">
            {{ totalData.aggreFateData?.vendorName }}
          </div>
          <div class="modmian-top-item">
            {{ totalData.aggreFateData?.manufacturersName }}
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="totalData.tableData"
          :loading="loading"
          :pagination="pagination"
          @change="pageChange"
        >
          <template v-slot:bodyCell="{ column, record }">
            <template v-if="column.key === 'aperture'">
              {{ record.aperture ?? '--' }}
            </template>
            <template v-else-if="column.key === 'onSieveQuality'">
              {{ record.onSieveQuality ?? '--' }}
            </template>
            <template v-else-if="column.key === 'gradeScreening'">
              {{ record.gradeScreening ?? '--' }}
            </template>
            <template v-else-if="column.key === 'accumulateScreening'">
              {{ record.accumulateScreening ?? '--' }}
            </template>
            <template v-else-if="column.key === 'detectionResults'">
              {{ record.detectionResults ?? '--' }}
            </template>
          </template>
          <!-- <template #footer>
            <a-table :columns="columns"
              :data-source="data1"
              :showHeader="false"
              :pagination="false">
            </a-table>
          </template> -->
        </a-table>
      </div>
      <template #footer> </template>
    </a-modal>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, watch } from 'vue';
import VChart from 'vue-echarts';
import { apiRight } from '@/api/api-right/api-right.js';
import { message } from 'ant-design-vue';
// import { computed } from 'vue';
import { nextTick } from 'vue';
import { useStore } from 'vuex';
import tool from './js/tool.js';
import chartConfigData from './js/chartConfigData.js';
const store = useStore();

const isCloudPage = process.env.VUE_APP_ISCLOUDPAGE === 'true';

// 细度模数页面上展示的数据
const finenessModulusResult = ref({});

const columns = [
  {
    title: '筛孔尺寸（mm）',
    dataIndex: 'aperture',
    key: 'aperture',
    width: 360,
  },
  {
    title: '分计筛余量(g)',
    dataIndex: 'onSieveQuality',
    width: 360,
    key: 'onSieveQuality',
  },
  {
    title: '分计筛余(%)',
    dataIndex: 'gradeScreening',
    width: 360,
    key: 'gradeScreening',
  },
  {
    title: '累计筛余(%)',
    key: 'accumulateScreening',
    dataIndex: 'accumulateScreening',
    width: 360,
  },
  {
    title: '通过百分率（%）',
    key: 'detectionResults',
    dataIndex: 'detectionResults',
    width: 360,
  },
];
// 弹窗所有数据
const totalData = reactive({
  //表格上当展示的数据信息
  aggreFateData: {},
  // tableTotalCount: 0,
  // 表格数据
  tableData: [
    // {
    //   key: '1',
    //   aperture: '37.6',
    //   onSieveQuality: '100',
    //   gradeScreening: '50',
    //   accumulateScreening: '40',
    //   detectionResults: '66'
    // }
  ],
});
// 请求数据的时候展示加载状态
let loading = ref(true);
// 表格分数数据配置信息
let pagination = ref({
  current: 1,
  // pageSize: 1,
  total: null,
  hideOnSinglePage: true,
  showSizeChanger: false,
  showQuickJumper: false,
});
const mchart = ref(null);
//展示表格弹窗的布尔值
const logShow = ref(false);
// 自定义markLine的数据
const xAxisTickData = ref(chartConfigData.finessxAxisTick);
// ecahrts渲染所需的原始默认数据
let finenessTotalData = ref(chartConfigData.finessEchartsData);
let ecahrtsFinenessData = reactive({
  upperLimitData: [],
  detectionResultsData: [],
  lowerLimitData: [],
});

// 细度模数集料类型
let aggregateType = ref(null);

// ecahrts配置项数据
const optionFineness = ref(
  tool.getOptions(xAxisTickData, ecahrtsFinenessData, 2.754, 0, false, false)
);

onMounted(() => {
  // console.log('细度模数信息', store.state.engine.finenessModulusDevice);
  // console.log('细度模数信息', finenessModulusDevice.value);
  // console.log('细度模数图表实例', mchart.value);
});
/**
 * @name 郭冲
 * @Date 2023-05-08 15:32:05
 * @introduction 显示表格弹窗
 * @description 请求并加载表格数据
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const modlShow = () => {
  logShow.value = true;
  getTableList();
};
/**
 * @name 郭冲
 * @Date 2023-05-08 15:33:07
 * @introduction 表格分页器页数变化的回调
 * @description 设置当前页为选中或输入的页数，并请求对应页的数据
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const pageChange = (page) => {
  let { current } = page;
  pagination.value.current = current;
  getTableList();
  // console.log('页码变化', pagination);
};
/**
 * @name 郭冲
 * @Date 2023-05-08 15:34:14
 * @introduction 获取表格数据
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const getTableList = () => {
  apiRight
    .getFinenessodulusChart({ page: pagination.value.current })
    .then((res) => {
      if (res.data.code === 0) {
        loading.value = false;
        // console.log('细度模数表格数据', res.data);
        // 表格上方展示的信息
        totalData.aggreFateData = res.data.data.aggreFateData;
        // totalData.tableTotalCount = res.data.data.count
        // pagination.value.total = res.data.data.count * 10; //返回的结果count是总页数，每页固定展示10条数据
        // 表格的临时数据
        let tempTableData = res.data.data.data.map((item) => {
          return {
            key: item.id,
            aperture: item.aperture, //筛孔尺寸
            onSieveQuality: item.onSieveQuality?.toFixed(1), //筛上重
            gradeScreening: item.gradeScreening?.toFixed(1), //分计筛余
            accumulateScreening: item.accumulateScreening?.toFixed(1), //累计筛余
            detectionResults: item.passRate?.toFixed(1), //通过百分率
          };
        });
        // 排序先把筛孔尺寸和筛底筛后总质量的数据筛选出来
        let numberArray = tempTableData
          .filter(
            (item) => item.aperture != '筛底' && item.aperture != '筛后总质量'
          )
          .sort((a, b) => b.aperture - a.aperture);
        let stringArray = tempTableData.filter(
          (item) => item.aperture == '筛底' || item.aperture == '筛后总质量'
        );
        totalData.tableData = [...numberArray, ...stringArray];

        // console.log('细度模数表格数据tableData', totalData.tableData);
        // console.log('细度模数表格数据aggreFateData', totalData.aggreFateData);
        // console.log('细度模数表格数据tableTotalCount', totalData.tableTotalCount);
      }
    })
    .catch((error) => {
      message.error(error.data.message, 2);
    });
};

/**
 * @name 郭冲
 * @Date 2023-09-07 11:42:13
 * @introduction 监听器-监听细度模数的结果数据，以及细度模数图表的新数据
 * @description 详细描述
 * @param {any} newValue 变化后的数据
 * @param {any} oldValue 变化前的数据
 * @return 无返回类型
 * @exception [违例类型] [违例类型说明]
 */
watch(
  [
    () => store.state.engine.allEngineData.finenessModulusDevice, //最新检测项的细度模数结果数据
    () => store.state.engine.allEngineData.finenessModulusCharts, //最新的级配图表数据
  ],
  ([newDeviceValue, newChartsValue], [oldDeviceValue]) => {
    nextTick(() => {
      finenessModulusResult.value = newDeviceValue || {};
      // 得到集料对应的类型
      aggregateType.value = getAggregateType(
        finenessModulusResult.value?.finenessModulus
      );
      // 设置最新的图表数据
      operateEchartsData(
        newChartsValue || [],
        oldDeviceValue || {},
        newDeviceValue || {}
      );
    });
  },
  { immediate: true }
);
const operateEchartsData = (newChartsValue, oldDeviceValue, newDeviceValue) => {
  if (Array.isArray(newChartsValue?.FinenessModulusCharts)) {
    let newFinessModulusCharts = newChartsValue?.FinenessModulusCharts?.sort(
      (a, b) => a.taylorValue - b.taylorValue
    );
    // 获取得到泰勒值和筛孔组成的刻度值
    getxAxisData(newFinessModulusCharts || []);

    // xAxisTickDataTemp和finenessTotalDataTemp用于设置图表的刻度和值的数据
    let xAxisTickDataTemp = [];
    let finenessTotalDataTemp = [];
    finenessTotalData.value = newFinessModulusCharts || []
    // console.log('获取vuex的细度模数数据', finenessTotalData.value);

    // 先清空ecahrtsFinenessData的上下限和实际值数据
    ecahrtsFinenessData.upperLimitData = [];
    ecahrtsFinenessData.detectionResultsData = [];
    ecahrtsFinenessData.lowerLimitData = [];
    // 9.5刻度的值全部为100
    finenessTotalData.value.forEach((item) => {
      if (item.aperture == '9.5') {
        item.detectionResults = 100.0;
        item.lowerLimit = 100.0;
        item.upperLimit = 100.0;
      }
    });

    // 如果上限和下限没有设置时该筛孔尺寸不要
    finenessTotalDataTemp = finenessTotalData.value
      .filter(
        (item) =>
          item.lowerLimit !== null &&
          item.upperLimit !== null &&
          item.lowerLimit !== undefined &&
          item.upperLimit !== undefined &&
          !item.aperture.includes('筛孔尺寸') &&
          item.aperture != '筛后总质量'
      )
      .map((ele) => {
        ele.aperture = tool.formatScreenSize(ele.aperture);
        return ele;
      });
    xAxisTickDataTemp = xAxisTickData.value
      .filter((item) => {
        return finenessTotalDataTemp.some(
          (ele) => tool.formatScreenSize(ele.aperture) == item.name
        );
      })
    // 找到去除上下限为undefined的轴之后，筛选出最大的x轴刻度值
    let initialMax = Math.max(...xAxisTickDataTemp.map((item) => item.xAxis));
    let initialMin = Math.min(...xAxisTickDataTemp.map((item) => item.xAxis));
    if (oldDeviceValue?.workId != newDeviceValue?.workId) {
      optionFineness.value = tool.getOptions(
        xAxisTickDataTemp,
        ecahrtsFinenessData,
        initialMax,
        initialMin,
        false,
        false
      );
      mchart.value?.setOption(optionFineness.value, true);
    }

    // 通过百分率值为100,并且上下限达到100，不显示后面的数据
    let firstFullFineness = finenessTotalDataTemp
      .filter((item) => item.aperture != '筛底')
      .find(
        (item) =>
          item.detectionResults == 100 &&
          item.upperLimit == 100 &&
          item.lowerLimit == 100
      );
    xAxisTickData.value.forEach((tickitem, tickindex) => {
      if (tickitem.name == firstFullFineness?.aperture) {
        optionFineness.value.xAxis.max = Number(tickitem?.xAxis || initialMax) + 0.01;
        mchart.value?.setOption(optionFineness.value, true);
      }
    });

    // 转换数据格式，图表展示用
    tool.formatData(
      finenessTotalDataTemp,
      ecahrtsFinenessData,
      xAxisTickDataTemp
    );
  }
};
/**
 * @introduction 泰勒值和筛孔组成的刻度值
 * @param { Array } originData sse推送的最新的echarts原始数据
 */
const getxAxisData = (originData) => {
  if (originData.length > 0) {
    xAxisTickData.value = originData
      .map((item) => {
        return {
          name: item.aperture,
          xAxis: item.taylorValue,
        };
      })
  }
};
/**
 * @name 郭冲
 * @Date 2023-09-07 11:43:54
 * @introduction 通过细度模数的值判断类型
 * @description 详细描述
 * @param {Number} value 细度模数的结果值
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const getAggregateType = (value) => {
  //保留一位小数，不四舍五入
  let decimalValue = Math.trunc(value * 10) / 10;
  if (decimalValue >= 3.1 && decimalValue <= 3.7) {
    return '粗砂';
  } else if (decimalValue >= 2.3 && decimalValue <= 3.0) {
    return '中砂';
  } else if (decimalValue >= 1.6 && decimalValue <= 2.2) {
    return '细砂';
  } else if (decimalValue >= 0.7 && decimalValue <= 1.5) {
    return '特细砂';
  }
};
</script>

<style scoped lang="scss">
@import '../../static/font.css';
@import '../assets/style/common.scss';

.card .card-center .card-center-left ul li .title {
  width: 44%;
}
.right-card-1 {
  .card-top {
    height: 16%;
    background: url(../assets/right/fineness.png) center/100% no-repeat;

    .card-top-left {
      width: 55%;
    }

    .card-top-center {
      width: 35%;
      font-family: 'PangMenZhengDao', sans-serif;

      span {
        font-size: 0.09rem;
      }
    }

    .card-top-right {
      width: 10%;

      .tabicon {
        text-align: right;

        img {
          width: 0.12rem;
          height: 0.12rem;
          opacity: 0.6;
        }
      }
    }
  }

  .card-center {
    height: 25%;

    .card-center-left {
      width: 1.2733rem;
      height: 0.45rem;
      margin-left: 2%;
      background: url(../assets/left/particle/jieguo.png) 0 0 no-repeat;
      background-size: 100% 100%;
    }

    .card-center-right {
      width: 50%;
      height: 100%;
    }
  }

  .card-bottom {
    height: 55%;
  }
}

// .close-btn {
//   margin-left: auto;
//   z-index: 9999;
//   background: none;
//   border: none;
//   font-size: 0.08rem;
//   color: white;
// }
</style>
