<template>
  <div class="app">
    <dv-full-screen-container>
      <!-- stars 头部 -->
      <div class="top-header">
        <top-header />
      </div>
      <!-- end 头部 -->

      <!-- stars 主体 -->
      <div class="main-content">
        <!-- stars 左边 -->
        <div class="main-content-left">
          <!-- 含水、含泥-细 -->
          <div class="left-card bg-main">
            <left-card-1 />
          </div>
          <!-- 含水、含泥-粗 -->
          <div class="left-card1 bg-main">
            <left-card-2 />
          </div>
          <!-- 亚甲蓝 -->
          <div class="left-card2 bg-main">
            <left-card-3 ref="leftCard3rf" />
          </div>
        </div>
        <!-- end 左边 -->

        <div class="main-content-main">
          <div class="main-content-top">
            <!-- 货物识别 -->
            <div class="top-left-card">
              <top-left-card-1 />
            </div>
            <!-- 掺配比例、砂率 -->
            <div class="top-center-card">
              <topCenterCard v-if="!isCloudPage" />
            </div>
            <!-- 取样 -->
            <div class="top-right-card">
              <top-right-card-1 />
            </div>
          </div>
          <!-- 3D模型 -->
          <div class="main-content-conts">
            <!-- <div class="model-caontainer">
              <modelCard></modelCard>
            </div> -->
            <div class="operate-btn" v-if="!isCloudPage">
              <a-popconfirm
                title="确认急停吗?"
                placement="left"
                @confirm="operation(9, '急停')"
              >
                <button class="button stop-btn">急停</button>
              </a-popconfirm>
              <!-- <a-popconfirm
                title="确认复位吗?"
                placement="left"
                @confirm="operation(0, '复位')"
              >
                <button class="button reset-btn">复位</button>
              </a-popconfirm> -->
            </div>

            <div class="model-caontainer-old" v-if="isOldModel"></div>
            <div class="model-caontainer" v-else></div>
          </div>
          <!-- 设备列表 -->
          <div class="main-content-foots">
            <content-foots />
          </div>
        </div>
        <div class="main-content-right">
          <!-- 颗粒级配-细 -->
          <div class="right-card bg-main">
            <right-card-1 />
          </div>
          <!-- 颗粒级配-粗 -->
          <div class="right-card1 bg-main">
            <right-card-2 />
          </div>
        </div>
      </div>
      <!-- end 主体 -->

      <!-- stars 底部 -->
      <div class="main-foots">
        <main-foots></main-foots>
      </div>
      <!-- end 底部 -->

      <!-- 菜单弹出层 -->
      <div class="menu-pop">
        <menu-pop @refreshMainFoot="refreshMainFoot" v-if="!isCloudPage" />
      </div>

      <!-- 故障和急停遮罩层 -->
      <!-- <div class="maskr">
        <div class="maskr-liner">
          <WarningOutlined  class="lc1-top-warn twinklewr" style="color: #fff;" />
          <div>故障：ED056</div>
        </div>
      </div> -->
    </dv-full-screen-container>
  </div>
</template>

<script setup>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { ref, onMounted, onUnmounted, reactive, watch } from "vue";
// import { apiCenterBottom } from '@/api/api-center-bottom/api-center-bottom.js';
import { apiOperationDevice } from "@/api/api-operation/api-operation.js";
import { message } from "ant-design-vue";

import topHeader from "./topHeader";
import leftCard1 from "./leftCard1";
import leftCard2 from "./leftCard2";
import leftCard3 from "./leftCard3";
import rightCard1 from "./rightCard1";
import rightCard2 from "./rightCard2";
// import modelCard from './modelCard.vue';
import topLeftCard1 from "./topLeftCard1";
import topCenterCard from "./topCenterCard";
import topRightCard1 from "./topRightCard1";
import contentFoots from "./contentFoots";
import mainFoots from "./mainFoots";
import menuPop from "./menuPop";
import { useStore } from "vuex";
import setting from "../config/setting";
import { useRoute } from "vue-router";
const route = useRoute();
// import screenfull from 'screenfull/dist/screenfull';
// import { nextTick } from 'vue';

// import { useStore } from 'vuex';
const store = useStore();

use([
  CanvasRenderer,
  PieChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

// let store = useStore();
const isOldModel = process.env.VUE_APP_ISOLDMODEL === "true";
const isCloudPage = process.env.VUE_APP_ISCLOUDPAGE === "true";
onMounted(async () => {
  // 本地代码运行时sse或socket连接
  if (setting.takeToken()) {
    // !不要删这部分代码
    // 页面刷新重连cocket
    // console.log('=====socket重连======');
    // if (store.state.user.socket) {
    //   console.log('====关闭socket连接====');
    //   store.state.user.socket.closeWebSocket();
    // }
    // store.state.user.socket.reconnect();

    // sse连接
    let sseInstance = store.state.user.detectionSseLocal;
    if (sseInstance) {
      sseInstance.closeSse();
      sseInstance.reconnect();
    } else {
      store.dispatch("user/initSse");
    }
  }
  //rem动态计算
  reRender();
  window.addEventListener("resize", debounce(runrsz, 600), false);
  document.addEventListener("DOMContentLoaded", debounce(runrsz, 600), false);
});

const runrsz = () => {
  reRender();
};
onUnmounted(() => {});
const props = reactive({
  width: 1920,
  height: 1080,
  rootValue: 192,
});
const fontSize = ref(192);
const debounce = (fn, delay) => {
  let timeout;

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
const reRender = () => {
  const docEl = document.documentElement;
  const screenWidth = docEl.clientWidth;
  const screenHeight = docEl.clientHeight;
  if (!screenWidth || !screenHeight) return;

  const screenRatio = screenWidth / screenHeight;
  const screenRatioDesign = props.width / props.height;

  if (screenRatio > screenRatioDesign) {
    // screenRatio > screenRatioDesign 说明屏幕比较宽 则 高度撑满 左右居中
    // console.log('screenRatio > screenRatioDesign 说明屏幕比较宽 则 高度撑满 左右居中')
    // 这边之所以 * screenRatioDesign 是因为 postcss 默认的 rootValue 是 1920 / 10
    // 用高度计算 fontSize 则需要把之前的关系乘上去
    fontSize.value = (screenHeight / 10) * screenRatioDesign;
  } else if (screenRatio < screenRatioDesign) {
    // screenRatio < screenRatioDesign 说明屏幕比较长 则宽度撑满 上下居中
    // console.log('screenRatio < screenRatioDesign 说明屏幕比较长 则宽度撑满 上下居中')
    fontSize.value = screenWidth / 10;
  } else {
    fontSize.value = screenWidth / 10;
  }
  document.documentElement.style.fontSize = fontSize.value.toFixed(5) + "px";
};

/**
 * @introduction 操作设备急停暂停等
 */
const operation = (type, operationType) => {
  apiOperationDevice
    .operateDetectionDevice(type)
    .then((res) => {
      if (res.data.code === 0) {
        message.success(`${operationType}成功`, 2);
      }
    })
    .catch(() => {
      message.error(`${operationType}失败`, 2);
    });
};

// 线上代码运行时sse连接
watch(
  [() => route.query],
  ([newQuery]) => {
    console.log("🚀 ~ watch ~ newQuery:", newQuery);
    if (newQuery.token) {
      let sseInstance = store.state.user.detectionSseOnlie;
      if (sseInstance) {
        sseInstance.closeSse();
        sseInstance.reconnect();
      } else {
        store.dispatch("user/initOnlieSse", newQuery);
      }
    }
  },
  { immediate: true }
);
watch(
  [() => store.state.engine.allEngineData],
  ([newValue]) => {
    let newErpToken = newValue?.deviceConfig?.erpToken || ''
    if (newErpToken) {
      sessionStorage.setItem('erpToken',newErpToken)
    }

  },

);
</script>

<style>
/* .ant-select-selection-item {
  font-size: 0.07rem !important;
} */
/* .ant-select-selection-item {
  font-size: 14px !important;
} */
.ant-modal div[aria-hidden="true"] {
  display: none !important;
}
.maskr {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0.4rem;
  background: rgba(203, 203, 203, 0.385);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.maskr-liner {
  display: flex;
  padding: 0.04rem 0.06rem;
  align-items: center;
  color: #fff;
  background: rgba(263, 39, 69, 0.7);
  border: 1px solid rgba(263, 39, 69, 0.7);
  font-size: 0.3rem;
}
.lc1-top-warn {
  font-size: 0.3rem;
  color: #ec2745;
  margin-right: 10px;
}
.registe-list {
  min-width: 720px !important;
}
html,
body,
#app {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  min-width: 1280px;
  background-color: #030409;
  /* 决定背景图像的位置是在视口内固定，或者随着包含它的区块滚动 */
  background-attachment: fixed;
  /* 图片可以保有其原有的尺寸，或者拉伸到新的尺寸，或者在保持其原有比例的同时缩放到元素的可用空间的尺寸。 */
  background-size: cover;
}
#dv-full-screen-container {
  position: relative;
  background-image: url("https://maoqiaooss.oss-cn-chengdu.aliyuncs.com/img_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: 0 0 3px blue;
  display: flex;
  flex-direction: column;
}
#dv-full-screen-container::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  opacity: 0.06;
  background-image: url("~@/assets/rotate.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform-origin: 50% 50%;
  animation: rotate 40s linear 0s infinite;
  pointer-events: none;
  z-index: -9999;
}
@keyframes rotate {
  from {
  }
  to {
    transform: rotate(360deg);
  }
}
.top-header {
  width: 100%;
  height: 7%;
}
.main-content {
  display: flex;
  height: 70%;
  padding: 0.1rem 0.14rem 0.14rem 0.14rem;
  box-sizing: border-box;
  column-gap: 1%;
}
.main-foots {
  height: 23%;
  padding: 0 0.14rem;
  box-sizing: border-box;
}
.menu-pop {
  pointer-events: none;
  position: absolute;
  z-index: 9999 !important;
  right: 0;
  top: 5%;
  width: 20%;
  height: 78vh;
  overflow: hidden;
  /* border: rgb(255, 255, 255) 1px solid; */
}
.left-card .border-box-content {
  padding: 0.08rem;
  box-sizing: border-box;
  display: flex;
}
.left-card1 .border-box-content {
  padding: 0.08rem;
  box-sizing: border-box;
  display: flex;
}
.left-card2 .border-box-content {
  padding: 0.08rem;
  box-sizing: border-box;
  display: flex;
}
.right-card .border-box-content {
  padding: 0.08rem;
  box-sizing: border-box;
  display: flex;
}
.dv-border-box-7 .border-box-conten {
  padding: 0.03rem;
  box-sizing: border-box;
  display: flex;
}
.foots-card .border-box-content {
  padding: 0.08rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-content .main-content-left {
  width: 20%;
  z-index: 999;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 1%;
}
.main-content .main-content-main {
  width: 60%;
  display: flex;
  flex-direction: column;
  /* padding: 0 0.08rem 0rem 0.08rem; */
  justify-content: space-between;
}
.main-content .main-content-right {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 1%;
}
.main-content-top {
  display: flex;
  height: 20%;
  position: relative;
  z-index: 9;
  justify-content: space-between;
}
.main-content-conts {
  /* background-color: #ffc2c2; */
  width: 100%;
  height: 63%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* padding-bottom: 0.1rem; */
}
.operate-btn {
  position: absolute;
  right: 0;
  top: 5%;
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  z-index: 999;
  .button {
    color: #f2f2f2;
    font-size: 0.085rem;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.01rem 0.06rem;
  }

  .button:active {
    top: 0.005rem;
    position: relative;
    transform: translateY(0.01rem);
  }
  .stop-btn {
    background-color: #ff5151;
    border: 1px solid #ff5959;
    box-shadow: 0 0.02rem 0 #ad3c3c;
  }
  .stop-btn:active {
    box-shadow: 0 0.005rem 0 #ad3c3c;
  }
  .reset-btn {
    background-color: #3fb581;
    border: 1px solid #44bd89;
    box-shadow: 0 0.02rem 0 #018c52;
  }
  .reset-btn:active {
    box-shadow: 0 0.005rem 0 #018c52;
  }
}

.model-caontainer,
.model-caontainer-old {
  width: 100%;
  height: 100%;
  background-image: url("https://maoqiaooss.oss-cn-chengdu.aliyuncs.com/model.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform-origin: 33% 75%;
  transform: scale(1.05);
}
.model-caontainer-old {
  background-image: url("https://maoqiaooss.oss-cn-chengdu.aliyuncs.com/model-old.png");
  transform-origin: 33% 35%;
  transform: scale(1.3);
}
.main-content-conts img {
  transform: scale(1.8);
  position: relative;
  bottom: 15%;
  z-index: 1;
}
.main-content-foots {
  box-shadow: 0px 0px 24px rgba(190, 190, 190, 0.048),
    0px 0px 50px rgba(202, 202, 202, 0.079);
  border: 1px solid #9cb4d5;
  border-radius: 5px;
  height: 13%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.main-content .left-card {
  height: 22%;
  /* margin-bottom: 2%; */
}
.main-content .left-card1 {
  height: 22%;
  /* margin-bottom: 2%; */
}
.main-content .left-card2 {
  height: 54%;
}
.main-content .right-card {
  height: 50%;
  /* margin-bottom: 2%; */
}
.main-content .right-card1 {
  height: 50%;
}
.main-content .top-left-card,
.top-right-card {
  box-shadow: 0px 0px 24px rgba(190, 190, 190, 0.048),
    0px 0px 50px rgba(202, 202, 202, 0.079);
  /* padding: 0.08rem; */
  box-sizing: border-box;
  display: flex;
  background-color: rgba(26, 49, 86, 0.5);
  width: 20%;
  height: 100%;
  border: 1px solid #9cb4d5;
  /* opacity: 0.8; */
  border-radius: 5px;
}
.main-content .top-center-card {
  width: 55%;
  height: 100%;
}
.main-content .top-content {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stars {
  background: #195894;
  border: 1px solid #9ecdfc;
}

.mbtns {
  font-size: 0.08rem;
  color: #fff;
  padding: 0.03rem 0.06rem;
  display: inline-block;
  border-radius: 0.02rem;
  width: 0.65rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mbtns .icons {
  font-size: 0.08rem;
  margin-left: 0.05rem;
}
.mcf-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mcf-top-right {
  display: flex;
  align-items: center;
}
.mcfbtn {
  padding: 0.02rem 0.06rem;
  box-sizing: border-box;
  margin-bottom: 0.03rem;
  border: 1px solid rgb(0, 183, 255, 0.4);
  /* background: radial-gradient( rgba(15, 92, 151,0.5), rgba(255, 255, 255,0.2)); */
  background: rgb(0, 183, 255, 0.4);
  color: #fff;
  font-size: 0.07rem;
  margin-left: 0.05rem;
  border-radius: 0.01rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mcfbtn .icons {
  font-size: 0.07rem;
  margin-right: 0.03rem;
}

/* 修改滚动条的样式 */
/* 鼠标悬停在滚动条上时的样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}
/*滚动条样式*/
::-webkit-scrollbar {
  width: 0.05rem; /*高宽分别对应横竖滚动条的尺寸*/
  height: 0.06rem;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0.05rem;
  -webkit-box-shadow: inset 0 0 0.05rem rgba(144, 147, 153, 0.2);
  background: rgba(144, 147, 153, 0.2);
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 0.05rem rgba(144, 147, 153, 0.2);
  border-radius: 5px;
  background: rgba(144, 147, 153, 0.1);
}
.qy-modal .ant-modal-footer {
  border-top: none;
}

.ant-modal-close-icon svg {
  color: rgb(45, 58, 81);
}

.table-modal .ant-modal,
.qy-modal .ant-modal {
  max-width: 90%;
  max-height: 90%;
  padding-bottom: 0;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}
.qy-modal .ant-modal {
  max-width: 60%;
}

.table-modal .ant-modal-header,
.report-modal .ant-modal-header,
.qy-modal .ant-modal-header {
  background: rgb(45, 58, 81);
  opacity: 1;
  border-bottom: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-modal .ant-modal-title,
.report-modal .ant-modal-title,
.qy-modal .ant-modal-title {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  color: white;
  font-size: 0.13rem;
  font-weight: bold;
}
.table-modal .ant-modal-content,
.report-modal .ant-modal-content,
.qy-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: calc(90vh);
  background: rgb(45, 58, 81, 0.8);
}
.qy-modal .ant-modal-content {
  height: calc(65vh);
}
.report-modal .ant-modal-content {
  height: 100%;
  background-color: #fff;
}

.table-modal .ant-modal-body,
.report-modal .ant-modal-body,
.qy-modal .ant-modal-body {
  flex: 1;
  overflow-y: auto;
}

.table-modal .ant-modal-footer,
.report-modal .ant-modal-footer,
.qy-modal .ant-modal-footer {
  border-top: none;
}
.modal-name {
  width: 100%;
  display: flex;
  justify-content: center;
}
.close-btn {
  position: absolute;
  right: 0;
  margin-left: auto;
  z-index: 9999;
  background: none;
  border: none;
  font-size: 0.08rem;
  color: white;
}
.footes {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.06rem;
}
.modmian-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.08rem;
  margin-bottom: 0.08rem;
}
.modmian-top-item {
  margin-right: 0.08rem;
  color: #fff;
}
.modmian-top .nonum {
  color: #e7a10b;
}

.agrund {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.agrund-item {
  width: 30%;
  margin-right: 1%;
  padding: 0.04rem 0;
  text-align: center;
  background: rgba(18, 27, 37, 0.8);
  border: 1px solid #9ecdfc;
  margin-bottom: 0.06rem;
  color: #fff;
  border-radius: 0.02rem;
  cursor: pointer;
  font-size: 0.07rem;
}
.agrund .agrundActive {
  background: rgb(0, 183, 255, 0.4);
  border: 1px solid rgb(0, 183, 255);
}
.fromline {
  display: flex;
  align-items: center;
  position: relative;
}
.fromline-name {
  font-size: 0.07rem;
  margin-left: 0.03rem;
  color: #fff;
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
}
.ant-table {
  background-color: transparent;
  color: white;
  border: 1px solid #9ecdfc;
}
.ant-table-thead > tr > th {
  font-size: 0.08rem;
  background-color: transparent;
  color: white;
  border-right: 1px solid #9ecdfc;
  border-bottom: 1px solid #9ecdfc;
}
.ant-table-tbody > tr > td {
  font-size: 0.08rem;
  background-color: transparent;
  border-right: 1px solid #9ecdfc;
  border-bottom: 1px solid #9ecdfc;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 0.07rem;
  background-color: transparent;
  border-right: 1px solid #9ecdfc;
  border-bottom: 1px solid #9ecdfc;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: transparent;
}
.pause {
  border: 1px solid #ff9d34;
  background-color: #ff9d34c1;
}
.bg-main {
  background: rgba(6, 12, 22, 0.3);
}
</style>
