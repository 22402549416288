<template>
  <div id="top-header">
    <!-- <dv-decoration-8 class="header-left-decoration" />
    <dv-decoration-5 class="header-center-decoration" />
    <dv-decoration-8 class="header-right-decoration" :reverse="true" /> -->
    <img
      style="width: 100%; height: 200%"
      src="../assets/top_header.gif"
      alt=""
    />
    <div class="left-status">
      <div class="all-status">
        <div class="login-status">气压:</div>
        <div class="cfotstars color1" v-if="pressureStatus"></div>
        <div class="cfotstars color3" v-else></div>
      </div>
      <div class="all-status">
        <div class="login-status">水压:</div>
        <div class="cfotstars color1" v-if="waterPressureStatus"></div>
        <div class="cfotstars color3" v-else></div>
      </div>

      <div>
        温度
        <span class="temperature-value">{{ temperature || '--' }}</span>
        <span>℃</span>
      </div>
      <div>
        湿度
        <span class="temperature-value">{{ humidity || '--' }}</span>
        <span>%</span>
      </div>
      <div @click="showLogList" class="log-list" v-if="!isCloudPage">日志</div>
    </div>
    <div class="center-title">
      <!-- <img src="../assets/logo-white.png" /> -->
      <span class="project-name">集料智能检测平台</span>
    </div>
    <div class="right-temperature">
      <p class="date">{{ currentTime }}</p>
      <div class="all-status">
        <div class="login-status" v-if="socketStatus">在线</div>
        <div class="login-status" v-else>离线</div>
        <div class="cfotstars color1" v-if="socketStatus"></div>
        <div class="cfotstars color3" v-else></div>
      </div>

      <a-tooltip :mouseEnterDelay="0" v-if="isCloudPage && !isFullScreen">
        <template #title>全屏</template>
        <FullscreenOutlined
          style="color: white; font-size: 0.15rem; margin-left: auto"
          @click="openFullScreen"
        />
      </a-tooltip>
      <a-tooltip :mouseEnterDelay="0" v-if="isCloudPage && isFullScreen">
        <template #title>还原</template>
        <FullscreenExitOutlined
          style="color: white; font-size: 0.15rem; margin-left: auto"
          @click="openFullScreen"
        />
      </a-tooltip>
      <div style="margin-left: auto" v-if="!isCloudPage">
        <div @click="handleLogout" style="cursor: pointer">
          <span class="exit">退出</span>
        </div>
      </div>
    </div>
    <!-- 日志组件 -->
    <LogList v-if="isLogVisible" v-model:visible="isLogVisible" />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import LogList from './cpns/menu/log-list.vue';
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
} from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import setting from '../../src/config/setting';
import { message } from 'ant-design-vue';
import dayjs from 'dayjs';
import screenfull from 'screenfull/dist/screenfull';
import tool from './js/tool';
import { useStore } from 'vuex';
// import { nextTick } from 'vue';
let store = useStore();
const router = useRouter();

const isFullScreen = ref(false);
const isCloudPage = process.env.VUE_APP_ISCLOUDPAGE === 'true';
let temperature = ref('');
let humidity = ref('');
let currentTime = ref(dayjs(new Date()).format('MM-DD HH:mm:ss'));
let interval = setInterval(() => {
  currentTime.value = dayjs(new Date()).format('MM-DD HH:mm:ss');
}, 1000);
const networkType = ref(true);
const socketStatus = ref(false);
const pressureStatus = ref(0);
const waterPressureStatus = ref(0);

//===========mt method start=============//
//退出登录
const handleLogout = () => {
  //清理缓存，跳转登录
  store.dispatch('user/removeToken').then(() => {
    store.dispatch('user/removeUser').then(() => {
      setting.remmoveUserRole();
      //关闭socket
      if (store.state.user.socket) {
        store.state.user.socket.closeWebSocket();
      }
      // 关闭sse连接
      let sseInstanceLocal = store.state.user.detectionSseLocal;
      sseInstanceLocal && sseInstanceLocal.closeSse();
      sessionStorage.removeItem(`${setting.clientId}`);
      // let sseInstanceOnline = store.state.user.detectionSseOnlie;
      // sseInstanceOnline && sseInstanceOnline.closeSse();
      // sessionStorage.removeItem(`${setting.clientIdOnline}`);

      message.success('成功退出登录');

      router.push('/login');
    });
  });
};
let isLogVisible = ref(false);
const showLogList = () => {
  isLogVisible.value = true;
};

onMounted(() => {
  checkConnectionStatus();
});
const openFullScreen = async () => {
  const element = document.documentElement;
  if (screenfull.isEnabled) {
    screenfull.toggle(element);
    isFullScreen.value = !isFullScreen.value;
  }
  // const element = document.documentElement;
  // console.log('screenfull is enabled:', screenfull.isEnabled);
  // if (screenfull.isEnabled) {
  //   try {
  //     await screenfull.toggle(element);
  //     isFullScreen.value = screenfull.isFullscreen;
  //   } catch (error) {
  //     console.error('Failed to toggle full screen:', error);
  //   }
  // } else {
  //   console.error('Full screen API is not supported.');
  // }
};
onUnmounted(() => {
  clearInterval(interval);
});
function checkConnectionStatus() {
  window.addEventListener('online', () => {
    networkType.value = window.navigator.onLine;
  });
  window.addEventListener('offline', () => {
    networkType.value = window.navigator.onLine;
    if (!networkType.value) {
      message.error('当前网络未连接，请检查网络!');
    }
  });
}
watch(
  [
    () => store.state.engine.allEngineData.temperature,
    () => store.state.engine.allEngineData.humidity,
    () => store.state.user.socketStatus,
    () => store.state.engine.allEngineData.pressureStatus,
    () => store.state.engine.allEngineData.waterPressureStatus,
  ],
  ([
    newTemperature,
    newHumidity,
    newSocketStatus,
    newPressureStatus,
    newWaterPressureStatus,
  ]) => {
    temperature.value = newTemperature ? newTemperature.toFixed(1) : '';
    humidity.value = newHumidity ? newHumidity.toFixed(1) : '';

    socketStatus.value = newSocketStatus ? newSocketStatus : false;
    pressureStatus.value = newPressureStatus ? newPressureStatus : false;
    waterPressureStatus.value = newWaterPressureStatus
      ? newWaterPressureStatus
      : false;
  },
  { immediate: true }
);
</script>

<style scoped>
@import '../../static/font.css';
/* .ant-modal-content {
  height: 50% !important;
} */
#top-header {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  /* border: 1px solid red; */
}

#top-header .header-center-decoration {
  width: 40%;
  height: 0.22rem;
  margin-top: 0.2rem;
}

#top-header .header-left-decoration,
#top-header .header-right-decoration {
  width: 25%;
  height: 0.26rem;
  margin-top: 0.12rem;
}
#top-header .left-status {
  width: 21%;
  height: 0.23rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  position: absolute;
  left: 0.14rem;
  /* gap: 5%; */
  top: 70%;
  font-size: 0.09rem;
  /* border: 1px solid red; */
}

.date {
  font-family: 'PangMenZhengDao', sans-serif;
  font-weight: 400;
  color: #fefefe;
  width: 0.9rem;
  margin: auto 0;
  font-size: 0.1rem;
}
.login-status {
  font-family: 'PangMenZhengDao', sans-serif;
  margin-left: auto;
  margin-right: 0.05rem;
  font-weight: 400;
  font-size: 0.1rem;
}
#top-header .center-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 0.12rem;
  font-weight: bold;
  left: 50%;
  top: 0.08rem;
  transform: translateX(-50%);
  color: #fff;
}

.project-name {
  font-size: 0.25rem;
  height: 0.22rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.14rem;
  letter-spacing: 0.02rem;
  font-family: PangMenZhengDao;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 0px 2px 0px rgba(42, 42, 42, 0.32);
  background: linear-gradient(-7deg, #ffffff 0%, #e7f0fd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#top-header .center-title img {
  width: 0.17rem;
  height: 0.17rem;
  object-fit: contain;
  transform: scale(1.6);
}

#top-header .right-icon,
.right-temperature {
  position: absolute;
  bottom: 0.05rem;
  right: 0rem;
  display: flex;
  width: 20%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.right-temperature {
  height: 0.23rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  top: 70%;
  /* gap: 10%; */
  color: white;
  width: 19%;
  right: 0.14rem;
  justify-content: flex-start;
  /* font-weight: 800; */
  font-size: 0.09rem;
}
.temperature-value {
  color: #ffca28;
  font-family: 'PangMenZhengDao', sans-serif;
  font-size: 0.1rem;
  vertical-align: middle;
}
#top-header .right-icon img {
  /* margin-right: 0.1rem; */
  width: 0.14rem;
  height: 0.14rem;
  cursor: pointer;
}

#top-header .right-icon .record {
  position: relative;
}

#top-header .right-icon .record span {
  padding: 0.01rem 0.03rem;
  font-size: 0.06rem;

  color: #fff;
  background: #ec2745;
  border-radius: 0.08rem;
  position: absolute;
  top: 0;
  left: 0.08rem;
  line-height: 0.06rem;
}

.modmian {
  width: 100%;
  height: 100%;
  padding: 0.08rem;
}
.all-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.log-list {
  font-family: 'PangMenZhengDao', sans-serif;
  cursor: pointer;
  margin-left: 0.05rem;
}
.table-modal .ant-modal {
  max-width: 90%;
  max-height: 90%;
  padding-bottom: 0;
  /*margin: 1%;*/
}

.table-modal .ant-modal-header {
  background: rgb(45, 58, 81);
  opacity: 1;
  border-bottom: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-modal .ant-modal-title {
  text-align: center;
  opacity: 1;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  font-weight: bold;
}

.table-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: calc(90vh);
  background: rgb(45, 58, 81, 0.8);
}

.table-modal .ant-modal-body {
  flex: 1;
}

.table-modal .ant-modal-footer {
  border-top: none;
}

.ant-modal-close-icon svg {
  color: rgb(45, 58, 81);
}

.ddddddd .ant-modal-content .ant-modal-header {
  margin-top: 100px;
  background: rgb(1, 18, 47);
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: -23px;
}

.typeTag {
  font-size: 0.07rem;
}

.btnmain {
  width: 100%;
  display: flex;
  align-items: center;
}

.btnbt {
  font-size: 0.06rem;
  color: #fff;

  padding: 0.02rem 0.08rem;
  display: inline-block;
  border-radius: 0.02rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.05rem;
}

.btcolor1 {
  background: rgba(263, 39, 69, 0.8);
  border: 1px solid rgb(263, 39, 69, 0.8);
}

.btcolor2 {
  background: rgba(250, 180, 33, 0.8);
  border: 1px solid rgb(250, 180, 33, 0.8);
}

.jcs td,
.jcs th {
  border: 1px solid white;
  font-size: 0.06rem;
  color: #fff;
}

.jcs td {
  padding: 0.04rem;
}

.jcs th {
  padding: 0.04rem;
  text-align: center;
}

.mdtitle {
  text-align: center;
}

.mswitch {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mdlin {
  width: 100%;
  display: flex;
  align-items: center;
}

.mdlin-item {
  display: flex;
  align-items: center;
  /* margin-right: 0.1rem; */
}

.mdlin-item span {
  font-size: 0.06rem;
  margin-right: 0.05rem;
}

.menu {
  background: #082a5d;
  /* backgrounrgba(8, 42, 93, 0.874)0, 183, 255, 0.7); */
}

.top-item {
  width: 0.6rem;
  height: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  right: 0.17rem;
}

.exit {
  font-style: italic;
  font-size: 0.07rem;
  width: 0.6rem;
  height: 0.25rem;
  display: block;
  background-image: url('../assets/quit.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  text-align: center;
  line-height: 0.25rem;
  transform: scale(1.1);
}
.exit:hover {
  background-image: url('../assets/quit-hover.png');
  transform: scale(1.1);
}
.top-item-setting {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  font-size: 0.07rem;
}

.top-item-setting:hover {
  background: rgb(0, 183, 255, 0.4);
}

.top-item-setting span {
  margin-left: 0.05rem;
  color: white;
  font-size: 0.06rem !important;
}
.cfotstars {
  width: 0.07rem;
  height: 0.07rem;
  border-radius: 50%;
  margin-right: 0.04rem;
}
.color1 {
  background: #00ff00;
  box-shadow: 0px 0px 5px #00ff00;
}
.color2 {
  background: #fab421;
  box-shadow: 0px 0px 5px #fab421;
}
.color3 {
  background: #ec2745;
  box-shadow: 0px 0px 5px #ec2745;
}
</style>
