<template>
  <!-- <span class="borderLine"></span> -->
  <!-- item.status  1为待检测  2为检测中  3为完成  4为未完成-->
  <div
    class="main-foots-card"
    :class="{ detecting1: sampleData.status == 2 }"
    :execute-standardname="sampleData.executionStandardName"
  >
    <div class="card-top">
      <img
        v-if="sampleData.executionStandard.includes('highway')"
        src="../../../assets/mainbottom/gong.svg"
        class="use-standard"
      />
      <img
        v-else-if="sampleData.executionStandard.includes('national')"
        src="../../../assets/mainbottom/guo.svg"
        class="use-standard"
      />
      <img
        v-else-if="sampleData.executionStandard.includes('railway')"
        src="../../../assets/mainbottom/tie.svg"
        class="use-standard"
      />
      <img
        v-else-if="sampleData.executionStandard.includes('hydraulic')"
        src="../../../assets/mainbottom/shui.svg"
        class="use-standard"
      />
      <div
        :class="{
          cardtopnum: sampleData.status == 2,
          cardtopnum1: sampleData.status != 2,
        }"
      >
        <a-tooltip placement="topLeft">
          <template #title>
            <span class="test-number">
              {{
                `${sampleData.testNumber.slice(
                  0,
                  sampleData.testNumber.length - 4
                )}--${sampleData.testNumber.slice(-4)}`
              }}
            </span>
          </template>
          <span class="test-number">
            {{
              `${sampleData.testNumber.slice(
                0,
                sampleData.testNumber.length - 4
              )}--${sampleData.testNumber.slice(-4)}`
            }}
          </span>
        </a-tooltip>
      </div>
      <!-- 设备故障显示状态和报告 -->
      <div v-if="sampleData.status === 4" class="cfotstars color3"></div>

      <!-- 完成状态显示报告 -->
      <!-- <div
        v-if="sampleData.status === 3 || sampleData.status === 4"
        class="card-top-right success-color"
      >
        <div class="bottom-status">
          <div
            class="download-report"
            @click="downloadAggregateRecord(sampleData.testNumber)"
          ></div>
        </div>
      </div> -->
      <a-button
        type="primary"
        ghost
        style="margin-left: auto"
        v-if="sampleData.launchEnable && !isCloudPage"
        @click="launchDetection(sampleData)"
      >
        启动
      </a-button>
      <a-button
        type="primary"
        ghost
        danger
        style="margin-left: auto"
        v-if="sampleData.status === 2 && !isCloudPage"
        @click="abandonDetection(sampleData.testNumber)"
      >
        取消
      </a-button>
      <!-- <div
        class="edit"
        v-if="sampleData.launchEnable"
        @click="launchDetection(sampleData)"
      >
        启动
      </div> -->
      <!-- <div
        class="abandon"
        v-if="sampleData.status === 2"
        @click="abandonDetection(sampleData.testNumber)"
      >
        取消
      </div> -->
    </div>
    <div class="divider"></div>
    <div class="card-name">
      {{ sampleData.aggregatesSpecs }}
    </div>
    <div class="card-factory">
      <div class="factory-name">
        <!-- 供应商 -->
        <a-tooltip placement="topLeft">
          <template #title v-if="sampleData.supplierName">
            {{ sampleData.supplierName }}
          </template>
          <div class="factory-text">{{ sampleData.supplierName }}</div>
        </a-tooltip>
      </div>
      <div class="factory-name">
        <!-- 生产商 -->
        <a-tooltip placement="topRight">
          <template #title v-if="sampleData.producerName">
            {{ sampleData.producerName }}
          </template>
          <div class="factory-text2">{{ sampleData.producerName }}</div>
        </a-tooltip>
      </div>
    </div>
    <div class="card-detection">
      <div
        class="card-detection-item"
        v-if="sampleData.sampleDetailsMap['取样'] !== undefined"
      >
        <div class="leftname">
          <div class="yuansu"></div>
          取样
        </div>
        <!-- 1完成 0错误 2 检测中 3待检测-->
        <CloseOutlined
          class="icons"
          :style="{ fontSize: '0.1rem', color: '#dc5a6e' }"
          v-if="sampleData.sampleDetailsMap['取样'] === 0"
        />
        <CheckOutlined
          class="icons"
          :style="{ fontSize: '0.1rem', color: '#74fef5' }"
          v-if="sampleData.sampleDetailsMap['取样'] === 1"
        />
        <a-spin
          class="icons"
          :indicator="indicator"
          style="margin-left: 0.04rem"
          v-if="sampleData.sampleDetailsMap['取样'] === 2"
        />
        <span
          class="span-text"
          v-if="
            sampleData.sampleDetailsMap['取样'] === undefined ||
            sampleData.sampleDetailsMap['取样'] == 3
          "
        >
          --
        </span>
      </div>
      <div
        class="card-detection-item"
        v-if="sampleData.sampleDetailsMap['含水率'] !== undefined"
      >
        <div class="leftname">
          <div class="yuansu"></div>
          含水率
        </div>
        <!-- 1完成 0错误 2 检测中 3待检测-->
        <CloseOutlined
          class="icons"
          :style="{ fontSize: '0.1rem', color: '#dc5a6e' }"
          v-if="sampleData.sampleDetailsMap['含水率'] === 0"
        />
        <CheckOutlined
          class="icons"
          :style="{ fontSize: '0.1rem', color: '#74fef5' }"
          v-if="sampleData.sampleDetailsMap['含水率'] === 1"
        />
        <a-spin
          class="icons"
          :indicator="indicator"
          style="margin-left: 0.04rem"
          v-if="sampleData.sampleDetailsMap['含水率'] === 2"
        />
        <span
          class="span-text"
          v-if="
            sampleData.sampleDetailsMap['含水率'] === undefined ||
            sampleData.sampleDetailsMap['含水率'] == 3
          "
        >
          --
        </span>
      </div>
      <div
        class="card-detection-item"
        v-if="sampleData.sampleDetailsMap['含泥率'] !== undefined"
      >
        <div class="leftname">
          <div class="yuansu"></div>
          <span
            v-if="
              sampleData.aggregatesSpecs.includes('机制砂') ||
              sampleData.aggregatesSpecs.includes('混合砂')
            "
          >
            石粉含量
          </span>
          <span v-else-if="sampleData.aggregatesSpecs.includes('碎石')">
            泥粉含量
          </span>
          <span v-else> 含泥量 </span>
        </div>
        <!-- 1完成 0错误 2 检测中 3待检测-->
        <CloseOutlined
          class="icons"
          :style="{ fontSize: '0.1rem', color: '#dc5a6e' }"
          v-if="sampleData.sampleDetailsMap['含泥率'] === 0"
        />
        <CheckOutlined
          class="icons"
          :style="{ fontSize: '0.1rem', color: '#74fef5' }"
          v-if="sampleData.sampleDetailsMap['含泥率'] === 1"
        />
        <a-spin
          class="icons"
          :indicator="indicator"
          style="margin-left: 0.04rem"
          v-if="sampleData.sampleDetailsMap['含泥率'] === 2"
        />
        <span
          class="span-text"
          v-if="
            sampleData.sampleDetailsMap['含泥率'] === undefined ||
            sampleData.sampleDetailsMap['含泥率'] == 3
          "
        >
          --
        </span>
      </div>
      <div
        class="card-detection-item"
        v-if="sampleData.sampleDetailsMap['颗粒级配'] !== undefined"
      >
        <div class="leftname">
          <div class="yuansu"></div>
          颗粒级配-粗
        </div>
        <!-- 1完成 0错误 2 检测中 3待检测-->
        <CloseOutlined
          class="icons"
          :style="{ fontSize: '0.1rem', color: '#dc5a6e' }"
          v-if="sampleData.sampleDetailsMap['颗粒级配'] === 0"
        />
        <CheckOutlined
          class="icons"
          :style="{ fontSize: '0.1rem', color: '#74fef5' }"
          v-if="sampleData.sampleDetailsMap['颗粒级配'] === 1"
        />
        <a-spin
          class="icons"
          :indicator="indicator"
          style="margin-left: 0.04rem"
          v-if="sampleData.sampleDetailsMap['颗粒级配'] === 2"
        />
        <span
          class="span-text"
          v-if="
            sampleData.sampleDetailsMap['颗粒级配'] === undefined ||
            sampleData.sampleDetailsMap['颗粒级配'] == 3
          "
        >
          --
        </span>
      </div>

      <div
        class="card-detection-item"
        v-if="sampleData.sampleDetailsMap['细度模数'] !== undefined"
      >
        <div class="leftname">
          <div class="yuansu"></div>
          颗粒级配-细
        </div>
        <!-- 1完成 0错误 2 检测中 3待检测-->
        <CloseOutlined
          class="icons"
          :style="{ fontSize: '0.1rem', color: '#dc5a6e' }"
          v-if="sampleData.sampleDetailsMap['细度模数'] === 0"
        />
        <CheckOutlined
          class="icons"
          :style="{ fontSize: '0.1rem', color: '#74fef5' }"
          v-if="sampleData.sampleDetailsMap['细度模数'] === 1"
        />
        <a-spin
          class="icons"
          :indicator="indicator"
          style="margin-left: 0.04rem"
          v-if="sampleData.sampleDetailsMap['细度模数'] === 2"
        />
        <span
          class="span-text"
          v-if="
            sampleData.sampleDetailsMap['细度模数'] === undefined ||
            sampleData.sampleDetailsMap['细度模数'] == 3
          "
        >
          --
        </span>
      </div>
      <div
        class="card-detection-item"
        v-if="sampleData.sampleDetailsMap['MB值'] !== undefined"
      >
        <div class="leftname">
          <div class="yuansu"></div>
          亚甲蓝
        </div>
        <!-- 1完成 0错误 2检测中 3待检测-->
        <CloseOutlined
          class="icons"
          :style="{ fontSize: '0.1rem', color: '#dc5a6e' }"
          v-if="sampleData.sampleDetailsMap['MB值'] === 0"
        />
        <CheckOutlined
          class="icons"
          :style="{ fontSize: '0.1rem', color: '#74fef5' }"
          v-if="sampleData.sampleDetailsMap['MB值'] === 1"
        />
        <a-spin
          class="icons"
          :indicator="indicator"
          style="margin-left: 0.04rem"
          v-if="sampleData.sampleDetailsMap['MB值'] === 2"
        />
        <span
          class="span-text"
          v-if="
            sampleData.sampleDetailsMap['MB值'] === undefined ||
            sampleData.sampleDetailsMap['MB值'] == 3
          "
        >
          --
        </span>
      </div>
    </div>
    <!-- 集料检测记录表 -->
    <detectionRecord
      v-if="aggregateRecordShow"
      v-model:visible="aggregateRecordShow"
      ref="aggregateRecordRef"
      :recordData="recordData"
    ></detectionRecord>
  </div>
</template>

<script setup>
import {
  LoadingOutlined,
  // WarningOutlined,
  CheckOutlined,
  CloseOutlined,
  // SnippetsOutlined
} from '@ant-design/icons-vue';
import { h, defineProps, toRefs, onMounted, ref, watchEffect } from 'vue';
import { apiMainfoots } from '@/api/api-main-foots/api-main-foots.js';
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import detectionRecord from '../menu/detection-record.vue';
import { useStore } from 'vuex';
let store = useStore();
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '20px',
    color: '#fff',
  },
  spin: true,
});
const isCloudPage = process.env.VUE_APP_ISCLOUDPAGE === 'true';

const props = defineProps({
  sampleData: {
    type: Object,
    require: true,
    default() {
      return {};
    },
  },
  // 如果有取样登记则不能启动样品登记
  sampleDetectionLength: {
    type: Number,
    require: true,
    default() {
      return 0;
    },
  },
});
let { sampleData, sampleDetectionLength } = toRefs(props);
// console.log('样品每一项',sampleData);
// 集料检测记录表显示
const aggregateRecordShow = ref(false);
// 集料检测记录表testNumber
const recordData = ref({});
// 当前检测标准
let standardCurrent = ref(null);
/**
 * @name 郭冲
 * @Date 2023-05-26 10:10:28
 * @introduction 监听器-监听vuex里的detecttionStandard的变化
 * @description 当变化的时候重新赋值给组件内的standardCurrent
 * @return 无返回类型
 * @exception [违例类型] [违例类型说明]
 */
watchEffect(() => {
  standardCurrent.value = store.state.device.detectionStandard;
});

onMounted(() => {});
/**
 * @name 郭冲
 * @Date 2023-07-18 16:52:47
 * @introduction 下载检测记录表
 * @description 详细描述
 * @param {Number} id 需要下载的记录表id
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
// const downloadAggregateRecord = () => {
//   aggregateRecordShow.value = true;
//   Object.assign(recordData.value, sampleData.value);

// };
/**
 * @name 郭冲
 * @Date 2023-08-30 11:30:46
 * @introduction 启动检测
 * @description 详细描述
 * @param {String | Number} testNumber 检测编号
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const launchDetection = (sampleData) => {
  // samplingManual为1代表手动取样登记，samplingSwitch为1代表地磅取样登记
  let isSampling = sampleData.samplingManual == 1 || sampleData.samplingSwitch == 1 ? true : false;
  Modal.confirm({
    title: sampleData.aggregatesSpecs,
    icon: createVNode(ExclamationCircleOutlined),
    content: createVNode(
      'div',
      {
        style: 'color:red;font-size:0.09rem;',
      },
      `${isSampling ?'请确认车辆是否停靠到位，确定后将启动取样钻头?': '请确认检测设备状态是否全部准备就绪?'}`
    ),
    maskClosable: true,
    style: `top:50%`,
    onOk() {
      let params = {
        testNumber: sampleData.testNumber,
      };
      // console.log(params);
      if (sampleDetectionLength.value == 0) {
        apiMainfoots
          .launchDetection(params)
          .then(() => {
            message.success('启动检测成功', 2);
          })
          .catch((error) => {
            // 处理请求错误
            console.error(error);
            message.error(`启动检测失败-${error.data.message}`, 2);
          });
      } else {
        message.error('等待取样登记检测完后才可启动', 2);
      }
    },
    onCancel() {
      // console.log('Cancel');
    },
    class: 'test',
  });
};
/**
 * @name 郭冲
 * @Date 2023-10-24 10:09:52
 * @introduction 取消检测
 * @description 详细描述
 * @param {String|Number} testNumber 检测单号
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const abandonDetection = (testNumber) => {
  Modal.confirm({
    title: sampleData.value.aggregatesSpecs,
    icon: createVNode(ExclamationCircleOutlined),
    content: createVNode(
      'div',
      {
        style: 'color:red;font-size:0.09rem;',
      },
      `确定取消检测？`
    ),
    maskClosable: true,
    style: `top:50%`,
    onOk() {
      let params = {
        testNumber: testNumber,
      };
      apiMainfoots
        .abandonDetection(params)
        .then(() => {
          message.success('取消检测成功', 2);
        })
        .catch((error) => {
          // 处理请求错误
          console.error(error);
          message.error(`取消检测失败-${error.data.message}`, 2);
        });
    },
    onCancel() {
      // console.log('Cancel');
    },
    class: 'test',
  });
};
</script>

<style lang="scss" scoped>
@import '../../../../static/font.css';

:deep(.ant-modal-confirm-body .ant-modal-confirm-title) {
  font-size: 0.07rem;
}
:deep(.ant-btn) {
  box-sizing: border-box;
  width: 0.25rem;
  height: 0.12rem;
  font-size: 0.06rem;
  padding: 0.04rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
:deep(.ant-btn-primary) {
  box-shadow: 0px 0px 3px #16dee9;
}
:deep(.ant-btn-dangerous) {
  box-shadow: 0px 0px 3px #ff4d4f;
}
.yuansu {
  width: 0.0667rem;
  height: 0.0667rem;
  margin-right: 0.03rem;
  background: url('../../../assets/common/yuansu1.png') center/cover no-repeat;
}
.divider {
  width: 100%;
  height: 8%;
  /* border: #ec2745 1px solid; */
  background-image: url('../../../assets/mainbottom/sample-divider.png');
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: cover;
}
// .edit,
// .abandon {
//   cursor: pointer;
//   font-size: 0.07rem;
//   color: white;
//   width: 0.38rem;
//   height: 0.25rem;
//   margin-left: auto;
//   display: block;
//   background-image: url('../../../assets/mainbottom/launch.svg');
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position: center;
//   text-align: center;
//   line-height: 0.25rem;
//   transform: scale(1.1);
// }
// .abandon {
//   background-image: url('../../../assets/mainbottom/cancel.svg');
// }

.waiting {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
}
.waiting-detection {
  width: 90%;
  color: #e7a10b;
  line-height: 0.15rem;
  border: solid 1px#e7a10b;
  text-align: center;
  border-radius: 0.02rem;
}
.cheking-detection {
  width: 90%;
  color: #1afa29;
  line-height: 0.15rem;
  border: solid 1px#1afa29;
  text-align: center;
  border-radius: 0.02rem;
}
.ant-pagination-total-text,
.ant-pagination-options-quick-jumper {
  color: #fff !important;
}
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.sample-list {
  min-width: 720px !important;
}

.main-foots-card {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 0.1rem;
  height: 95%;
  box-shadow: 0px 0px 24px rgba(190, 190, 190, 0.048),
    0px 0px 50px rgba(202, 202, 202, 0.079);
  background-color: rgba(1, 1, 1, 0.2);
  border-radius: 2px;
}
.main-foots-card::after {
  display: block;
  content: attr(execute-standardname);
  position: absolute;
  bottom: 0.02rem;
  right: 0.05rem;
  color: #cbcbcb2a;
  font-size: 0.14rem;
  font-weight: bold;
}
.detecting {
  background-image: url('../../../assets/load.gif');
  background-repeat: no-repeat;
  background-position: 40.8% 61%;
  background-size: 113.3% 125%;
}
.detecting1 {
  background-image: url('../../../assets/loading.gif');
  background-repeat: no-repeat;
  background-position: 49% 76%;
  background-size: 109.5% 113.5%;
  background-color: rgba(192, 192, 192, 0.2);
  animation: highlight 2.5s linear infinite;
}

@keyframes highlight {
  0% {
    background-color: rgba(1, 1, 1, 0.2);
  }

  50% {
    background-color: rgba(0, 0, 0, 0.4);
  }

  100% {
    background-color: rgba(1, 1, 1, 0.2);
  }
}
.main-foots-card .border-box-content {
  padding: 0.1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.cheking {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cfotstars {
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 50%;
  margin: 0rem 0.04rem;
}
.color1 {
  background: #00ff00;
}
.color2 {
  background: #fab421;
}
.color3 {
  background: #ec2745;
}
.cfotstars-warn {
  font-size: 0.1rem;
  color: #ec2745;
  margin-right: 10px;
}
.twinkle {
  animation: star 1.2s linear infinite;
  box-shadow: 0px 0px 10px #00ff00;
}
.twinklewr {
  animation: star 1.2s linear infinite;
}
@keyframes star {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2);
  }

  100% {
    opacity: 0.6;
    transform: scale(1);
  }
}

.card-top {
  height: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.05rem;
  /* border: #ec2745 1px solid; */
  .use-standard {
    max-width: 0.11rem;
    max-height: 0.137rem;
    margin-right: 0.03rem;
  }
  .test-number {
    width: 77%;
    font-size: 0.09rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.cardtopnum,
.cardtopnum1 {
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-start;
  // align-items: center;

  font-family: 'PangMenZhengDao', sans-serif;
  font-weight: 400;
  color: #f3c576;
  height: 100%;
  // min-width: 85%;
}
.cardtopnum1 {
  color: #ffffff;
}
.card-top-right {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 0.07rem;
}
.card-name {
  font-size: 0.08rem;
  margin-top: 0.02rem;
  color: #fff;
  height: 10%;
  padding: 0rem 0.05rem 0rem 0.03rem;
}
.card-factory {
  font-size: 0.08rem;
  margin-top: 0.02rem;
  padding-left: 0.03rem;
  color: #fff;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2%;
  .factory-name {
    width: 49%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .factory-text {
    width: 100%;
    white-space: nowrap; /* 防止换行 */
    overflow: hidden; /* 隐藏溢出部分 */
    text-overflow: ellipsis; /* 显示省略号 */
  }
  .factory-text2 {
    width: 100%;
    white-space: nowrap; /* 防止换行 */
    overflow: hidden; /* 隐藏溢出部分 */
    text-overflow: ellipsis; /* 显示省略号 */
    text-align: right;
    // display: flex;
    // flex-direction: row;
    // justify-content: flex-end;
    // align-items: center;
  }
}
.card-detection {
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0.06rem;
  padding: 0rem 0.03rem;
}
.card-detection-left {
  width: 50%;
  /* padding-right: 0.08rem; */
}
.card-detection-right {
  width: 50%;
  /* padding-left: 0.08rem; */
}
.card-detection-item {
  width: 50%;
  /* margin-bottom: 0.04rem; */
  display: flex;
  align-items: center;
  gap: 5px;
  /* padding-right: 0.1rem; */
  /* justify-content: space-between; */
}
.card-detection-item .leftname {
  font-size: 0.08rem;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.card-detection-item .icons {
  font-size: 0.08rem;
  margin-left: 0.1rem !important;
}
.card-detection-item .success-color {
  color: #00ff00;
}
.card-detection-item .hitch-color {
  color: #ec2745;
}
.btns {
  font-size: 0.07rem;
  color: #fff;
  background: rgb(0, 183, 255, 0.4);
  border: 1px solid rgb(0, 183, 255);
  padding: 0.02rem 0.08rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.02rem;
  width: 0.45rem;
  cursor: pointer;
}
.btns .bticon {
  font-size: 0.08rem;
  margin-left: 0.04rem;
}
.merrors {
  font-size: 0.07rem;
  color: #ec2745;
}
.main-foots-right {
  padding: 0.03rem 0.06rem;
  box-sizing: border-box;
  margin-bottom: 0.03rem;
  border: 1px solid rgb(0, 183, 255, 0.4);
  /* background: radial-gradient( rgba(15, 92, 151,0.5), rgba(255, 255, 255,0.2)); */
  box-shadow: rgba(15, 92, 151, 0.4) 0px 0px 0.2rem inset;
  color: #fff;
  font-size: 0.08rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.main-foots-right .ricon {
  font-size: 0.1rem;
  margin-bottom: 0.06rem;
}
.ant-form-item-label > label {
  color: #fff;
}
.span-text {
  color: white;
  margin-left: 0.12rem;
}
.bottom-status {
  flex: 1;
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.download-report {
  width: 0.12rem;
  height: 0.12rem;
  cursor: pointer;
  background-image: url('../../../assets/mainbottom/download.png');
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 100%;
}
.download-report:hover {
  background-image: url('../../../assets/mainbottom/download_hover.png');
}
:deep(.ant-pagination-item-ellipsis) {
  color: #fff !important;
}
</style>
