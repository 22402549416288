<template>
  <div class="left-card-1">
    <div class="cargo-identification">
      <a-space>
        <img
          src="../assets/quyang-new.png"
          alt=""
          style="width: 0.12rem; height: 0.12rem"
        />
        <span>取样</span>
      </a-space>
      <div class="cargo-status">
        <!-- 离线0，准备1，故障2，就绪3，运行中4，完成5，暂停中6 ，手动7，本地8，急停中9-->
        <DeviceStatus :deviceStatus="sampleBitDevice?.status ?? 0" />
        <setting-outlined
          @click="setSampleDeviceShow"
          :style="{ fontSize: '0.09rem' }"
          v-if="!isCloudPage"
        />
        <!-- <a-spin
          class="icons"
          :indicator="indicator"
          v-if="sampleBitDevice?.status == 4"
        /> -->
      </div>
    </div>
    <div class="cargo-aggregatesSpecs">
      {{ sampleBitDevice?.aggregatesSpecs ?? '--' }}
    </div>
    <div class="divider"></div>
    <div class="cargo-result">
      {{
        `${sampleBitDevice?.workId?.slice(0, 4) || '-'}-${
          sampleBitDevice?.workId?.slice(-4) || '-'
        }`
      }}
    </div>
  </div>

  <!--取样-钻头-参数设置-->
    <a-modal
      v-model:visible="samplingDeviceShow"
      width="50%"
      centered
      :wrapClassName="'qy-modal'"
      :closeIcon="true"
      @cancel="closeSampling"
    >
      <template #title>
        <div ref="modalTitleRef" class="setting-title">钻头-参数设置</div>
        <a-button class="close-btn" @click="closeSampling">关闭 </a-button>
      </template>
      <div
        style="padding: 0 0.5rem"
        v-if="JSON.stringify(setting.bitParameter) !== '{}'"
      >
        <div class="setmina">
          <div class="setmina-item">
            <div class="setmina-item-title">取样点_x轴</div>
            <a-input
              suffix="%"
              v-model:value="setting.bitParameter.position_x"
            />
          </div>
          <div class="setmina-item">
            <div class="setmina-item-title">取样点_y轴</div>
            <a-input
              suffix="%"
              v-model:value="setting.bitParameter.position_y"
            />
          </div>
          <div class="setmina-item">
            <div class="setmina-item-title">取样点_z轴</div>
            <a-input
              suffix="%"
              v-model:value="setting.bitParameter.position_z"
            />
          </div>
        </div>
      </div>
      <div class="dry-loading" v-else>
        <a-spin size="large" />
      </div>
      <template #footer>
        <div class="footes" style="padding-bottom: 0.06rem">
          <div class="mbtns stars" @click="handleSaveSampling">
            <span>保存</span>
          </div>
        </div>
      </template>
    </a-modal>
</template>

<script setup>
import { ref, watch,reactive } from 'vue';
import { useStore } from 'vuex';
import { SettingOutlined } from '@ant-design/icons-vue';
import DeviceStatus from './cpns/device/device-status.vue';
import { message } from 'ant-design-vue';
import { apiCenterBottom } from '@/api/api-center-bottom/api-center-bottom.js';

const store = useStore();
// const indicator = h(LoadingOutlined, {
//   style: {
//     fontSize: '20px',
//     color: '#fff',
//   },
//   spin: true,
// });
const isCloudPage = process.env.VUE_APP_ISCLOUDPAGE === 'true';

let sampleBitDevice = ref({
  workId: null, //工单号
  aggregatesSpecs: null, //集料规格
  status: null, //设备状态
});
//取样弹框
const samplingDeviceShow = ref(false);
//整个设备列表对应设备数据
const setting = reactive({
  bitParameter: {}, //取样设备
});


/**
 * @Date 2023-05-07 14:06:11
 * @introduction 获取取样设备参数
 * @description 打开取样设备参数时获取设备参数并展示
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const setSampleDeviceShow = () => {
  samplingDeviceShow.value = true;
  // 获取取样设备参数
  apiCenterBottom
    .getDeviceSettings()
    .then((res) => {
      if (res.data.code == 0) {
        // console.log('取样设备参数', res.data.data)
        setting.bitParameter = JSON.parse(res.data.data[0].sampling);
        // console.log('取样设备参数', setting.bitParameter);
      }
    })
    .catch(() => {
      message.error('获取设备参数失败', 2);
    });
};
/**
 * @introduction 保存取样设备参数
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const handleSaveSampling = () => {
  if (
    setting.bitParameter.position_x === '' ||
    setting.bitParameter.position_y === '' ||
    setting.bitParameter.position_z === ''
  ) {
    message.error('输入的值不能为空');
    return;
  }
  let data = {
    sampling: JSON.stringify(setting.bitParameter),
  };
  apiCenterBottom
    .saveDeviceSettings(data)
    .then((res) => {
      if (res.data.code == 0) {
        message.success('修改成功');
        closeSampling();
      }
    })
    .catch(() => {
      message.error('修改失败');
    });
};
/**
 * @Date 2023-08-18 08:42:12
 * @introduction 关闭烘干设备参数弹框
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const closeSampling = () => {
  samplingDeviceShow.value = false;
  setting.bitParameter = {};
};
watch(
  [
    () => store.state.engine.allEngineData.sampleBitDevice,
    () => store.state.engine.allEngineData.samplingDeviceStatus,
  ],
  ([newResult, newStatus]) => {
    Object.assign(sampleBitDevice.value, newResult);
    Object.assign(sampleBitDevice.value, { status: newStatus });
  },
  { immediate: false }
);
</script>

<style scoped>
@import '../../static/font.css';
.left-card-1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 3%;
}
.cargo-identification {
  padding: 0px 0.08rem;
  display: flex;
  width: 100%;
  height: 32%;
  border-radius: 5px 5px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.09rem;
  color: #ffffff;
  background-color: rgba(17, 34, 62, 0.5);
  font-weight: 600;
}
.cargo-status {
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 0.05rem;
}
.cargo-aggregatesSpecs {
  padding: 0px 0.08rem;
  font-size: 0.08rem;
  color: #cccccc;
  height: 26%;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
}
.identify-status-notconform {
  font-size: 0.08rem;
  color: #fc5555;
  font-weight: 400;
}
.identify-status-conform {
  font-size: 0.08rem;
  color: #5bde75;
  font-weight: 400;
}
.cargo-result {
  height: 26%;
  width: 100%;
  padding: 0px 0.08rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #dfb671;
}
.divider {
  width: 88%;
  height: 1px;
  margin: 0px 6%;
  /* background: radial-gradient(#2d538e, #86b4e6, #2d538e); */
  background-color: #aad8fa;
}
.setmina {
  display: flex;
  align-items: center;
}
.setmina-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 0 0.03rem;
}
.setting-dry-item {
  color: white;
  font-size: 0.09rem;
  font-weight: 800;
  width: 0.4rem;
}
.setmina-item-title {
  text-align: center;
  font-size: 0.08rem;
  color: #fab421;
  margin-bottom: 0.04rem;
}
.dry-loading {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
