import { EventSourcePolyfill } from 'event-source-polyfill'
import tool from '../views/js/tool'
import { message } from 'ant-design-vue'
import setting from './setting'
import store from '@/store';
const baseUrl = process.env.VUE_APP_BASE_URL;
const baseUrlOnlie = process.env.VUE_APP_BASE_URL_ONLINE;

export class detectionSse {
  constructor(isOnline = false, detail = {}) {
    this.isOnline = isOnline
    this.detail = detail
    this.headers = {}
    this.heartInterval = 1000 * 10
    this.heartSeverTimeout = 1000 * 6
    this.url = baseUrl
    this.messageBox = message
    this.waitTime = false //判断发送订阅消息超时未回复时状态
  }

  initSee() {
    if (window.EventSource) {
      // 线上环境初始化
      if (this.isOnline) {
        const clientIdKey = setting.clientIdOnline;
        let clientIdOnline = sessionStorage.getItem(clientIdKey);
        if (!clientIdOnline) {
          clientIdOnline = Date.now();
          sessionStorage.setItem(clientIdKey, clientIdOnline);
        }
        // 不传token配置  t--租户编号, s--租户编号 , d--检测线编号 , c--客户端id
        if (this.detail.t && this.detail.s) {
          this.url = `${baseUrlOnlie}/at/detection/createSseConnect?c=${clientIdOnline}&d=${this.detail.d}&t=${this.detail.t}&s=${this.detail.s}`
        } else {
          // 有传token线上地址请求头配置   t--租户编号, s--租户编号 , d--检测线编号 , c--客户端id
          this.url = `${baseUrlOnlie}/at/detection/createSseConnect?c=${clientIdOnline}&d=${this.detail.d}`
          Object.assign(this.headers, { token: this.detail.token })
        }
      } else {
        // 本地环境初始化
        const clientIdKey = setting.clientId;
        let clientId = sessionStorage.getItem(clientIdKey);
        if (!clientId) {
          clientId = Date.now();
          sessionStorage.setItem(clientIdKey, clientId);
        }

        this.url = `${baseUrl}/api/device/engine/createSseConnect?clientId=${clientId} `
        Object.assign(this.headers, {
          Authorization: setting.takeToken(`${setting.tokenStoreName}`),
        })
      }

      // heartbeatTimeout:心跳超时监测 30s
      this.source = new EventSourcePolyfill(this.url, {
        headers: this.headers,
        heartbeatTimeout: 30000
      })
      // 连接成功
      this.source.onopen = this.setOnopenMessage()
      // 收到消息的回调
      this.source.onmessage = this.setOnmessageMessage()
      // 连接错误
      this.source.onerror = this.setErrorMessage()

      this.subscriptions = ''
      this.subscriptionsSilo = ''
      this.subscriptionstanker = ''
    } else {
      message.warning('该浏览器不支持消息功能')
    }
  }

  reconnect() {
    // 重新连接
    if (this.lockReconnect) return
    this.lockReconnect = true
    //没连接上会一直重连，设置延迟避免请求过多
    this.timeoutnum && clearTimeout(this.timeoutnum)
    this.timeoutnum = setTimeout(() => {
      //新连接
      this.initSee()
      this.lockReconnect = false
    }, 1000 * 5)
  }

  setOnmessageMessage() {
    return (event) => {
      const result = JSON.parse(event.data)
      // console.log("🚀 ~ detectionSse ~ return ~ result:", result)
      const code = result.code
      const data = result.data
      if (code === 200) {
        try {
          let sseData = JSON.parse(data)
          // console.log("🚀 ~ detectionSse ~ return ~ sseData:", sseData)
          // store.dispatch("engine/handleEngineSseData", sseData);
          store.commit("engine/handleEngineSseData", sseData);
        } catch (error) {
          console.error(error);
        }
      } else if (code === 0) {
        // 初次建立连接，客户端id储存本地
        // if (this.isOnline) {
        //   tool.data.set(`${setting.clientIdOnline}`, data)
        //   return
        // }
        // tool.data.set(`${setting.clientId}`, data)
      }
    }
  }

  setErrorMessage() {
    return (e) => {
      console.log(e)
      console.log('detectionSse连接发生错误')
      store.commit("engine/handleEngineSseData", {});
      // store.dispatch('user/setSocketStatus', false)
      //   .then(() => {
      //     console.log("设置未连接状态成功")
      //   });
      // 手动重连
      this.closeSse()
      //重连
      this.reconnect()
    }
  }

  setOnopenMessage() {
    return () => {
      console.log('detectionSse连接成功')
      // this.store.detectionSseLoding = true
      store.dispatch('user/setSocketStatus', true)
        .then(() => {
          console.log("设置连接状态成功")
        });
    }
  }

  closeSse() {
    this.source.close()
    store.dispatch('user/setSocketStatus', false)
      .then(() => {
        console.log("设置未连接状态成功")
      });
  }

  stompSubscribe(messageCallback) {
    this.subscriptions = messageCallback
  }
  stompSubscribetanker(messageCallback) {
    this.subscriptionstanker = messageCallback
  }
  stompSubscriSilo(messageCallback) {
    this.subscriptionsSilo = messageCallback
  }
}
